<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-form
          ref="formName"
          @submit.prevent
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <validation-observer ref="simpleRules">
              <b-overlay
                :show="invoiceData.showOverLay "
                rounded="sm"
                :opacity="0.5"
              >
                <inv-header :invoice-data="invoiceData" />
                <inv-item :invoice-data="invoiceData" />
                <inv-footer :invoice-data="invoiceData" />
                <b-col cols="6">
                  <!-- alert -->
                  <b-alert
                    v-height-fade.appear
                    variant="danger"
                    :show="invoiceData.showError"
                    class="text-left p-1"
                  >
                    <ul class="list-style-icons mb-0">
                      <li><feather-icon icon="ArrowRightIcon" />{{ invoiceData.apiErrors }}</li>
                    </ul>
                  </b-alert>
                </b-col>
                <b-col cols="3" />
              </b-overlay>
            </validation-observer>
          </b-card>
        </b-form>
      </b-col>
      <inv-right
        :invoice-data="invoiceData"
        @submit-form="validationForm"
        @clear-form="clearForm"
      />
    </b-row>
  </section>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BCard, BForm, BOverlay, BAlert,
} from 'bootstrap-vue'
import { heightFade } from '@core/directives/animations'
import invHeader from '@/views/components/invoice/inv-header.vue'
import invItem from '@/views/components/invoice/inv-item.vue'
import invFooter from '@/views/components/invoice/inv-footer.vue'
import invRight from '@/views/components/invoice/inv-right.vue'
import purchaseInvoice from '@/service/purchase/purchase-invoice'
import document from '@/service/administration/document'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import status from '@/service/administration/status'
import warehouse from '@/service/warehouse/warehouse'
import financialYear from '@/service/accounting/financial-year'

export default {
  components: {
    ValidationObserver,
    invHeader,
    invItem,
    invFooter,
    invRight,
    BRow,
    BCol,
    BCard,
    BAlert,
    BForm,
    BOverlay,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      user: '',
    }
  },
  async created() {
    this.user = JSON.parse(localStorage.getItem('userData'))
    this.loadLabel()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.postApi()
        }
        this.switchLoc()
      })
    },
    async postApi() {
      this.invoiceData.showOverLay = true
      const formData = new FormData()
      formData.append('date', this.invoiceData.master.date)
      formData.append('due_date', this.invoiceData.master.due_date)
      formData.append('organization_branch_id', this.invoiceData.master.organization_branch_id)
      formData.append('warehouse_id', this.invoiceData.master.warehouse_id.id)
      formData.append('financial_year_id', this.invoiceData.master.financial_year_id ? this.invoiceData.master.financial_year_id.id : 0)
      formData.append('vendor_set_id', this.invoiceData.client.id)
      formData.append('total_price', this.invoiceData.master.total_price)
      formData.append('document_type_id', this.invoiceData.document ? this.invoiceData.document.id : 0)
      formData.append('document', JSON.stringify(this.invoiceData.document))
      formData.append('based_document_id', this.invoiceData.master.based_document_id)
      formData.append('based_document_type_id', this.invoiceData.master.based_document_type_id ? this.invoiceData.master.based_document_type_id.id : 0)
      formData.append('based_document_no', this.invoiceData.master.based_document_no)
      formData.append('description', this.invoiceData.master.description)
      formData.append('suspended', this.invoiceData.master.suspended ? 1 : 0)
      formData.append('status_id', this.invoiceData.master.status_id ? this.invoiceData.master.status_id.id : 0)
      formData.append('user_id', this.user.id)
      formData.append('items', JSON.stringify(this.invoiceData.items))
      // eslint-disable-next-line no-plusplus
      for (let i = 0, l = this.invoiceData.file.length; i < l; i++) {
        formData.append(`file[${i}]`, this.invoiceData.file[i])
      }
      await purchaseInvoice.postAxios(formData).then(() => {
        // this.$refs.formName.reset()
        this.invoiceData.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => {
        this.clearForm()
        this.invoiceData.showOverLay = false
      })
    },
    // handleFormDataCallback($event) {
    //   console.log('----------------------------------------------------------omer ---------------------------------------')
    //   console.log($event)
    //   console.log('----------------------------------------------------------omer ---------------------------------------')
    // },
    clearForm() {
      this.invoiceData = {
        master: {
          id: 0,
          date: '',
          due_date: '',
          description: '',
          based_document_id: '',
          based_document_type_id: '',
          organization_branch_id: '',
          based_document_no: '',
          document_no: '',
          total_price: 0,
          warehouse_id: '',
          financial_year_id: 0,
          status_id: '',
          suspended: false,
        },
        client: null,
        clientOrVenderName: 'Supplier',
        clientOrVenderId: 1,
        document_id: 11,
        document: '',
        items: [],
        file: [],
        showError: false,
        showOverLay: false,
        apiErrors: [],
        documentName: 'Invoice',
        routEdit: 'purchase-updatePurchasesInvoice',
        routePerview: 'purchase-previewInvoice',
        permissionEdit: 'PurchasesInvoice',
        to: 'Invoice To',
        tableItems: 'purchase_invoice_details',
        send: true,
        preview: false,
        reset: true,
        save: true,
        isEdit: false,
        edit: false,
        payment: false,
        print: false,
        download: false,
        saveUpdateText: 'Save',
      }
      this.getDocumentNo()
      this.loadLabel()
      this.reloadInitForm()
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    async getDocumentNo() {
      this.invoiceData.showOverLay = true
      await document.oneAxios(this.invoiceData.document_id).then(response => {
        this.invoiceData.document = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await document.getDocumentNoAxios(this.invoiceData.document).then(response => {
        this.invoiceData.master.document_no = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      }).finally(() => { this.invoiceData.showOverLay = false })
    },
    loadLabel() {
      this.invoiceData.documentName = this.$t('Purchases Invoice')
      this.invoiceData.to = this.$t('InvoiceInfo.Invoice To')
      this.invoiceData.clientOrVenderName = this.$t('Supplier')
      this.invoiceData.saveUpdateText = this.$t('General.Save')
      this.invoiceData.master.organization_branch_id = this.user.employee.branch_id
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))
    },
    async reloadInitForm() {
      this.$refs.simpleRules.reset()
      const today = new Date()
      const dateNow = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      this.invoiceData.master.date = dateNow
      this.invoiceData.master.due_date = dateNow
      await status.getAxios().then(response => {
        this.status_option = response.data
        if (response.data) {
          // eslint-disable-next-line prefer-destructuring
          this.invoiceData.master.status_id = this.status_option[0]
        }
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await warehouse.getAxios().then(response => {
        this.warehouse_option = response.data
        if (response.data) {
          // eslint-disable-next-line prefer-destructuring
          this.invoiceData.master.warehouse_id = this.warehouse_option[0]
        }
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await financialYear.getAxios().then(response => {
        this.invoiceData.master.financial_year_id = response.data.find(x => x.active === 1)
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      }).finally(() => { this.invoiceData.showOverLay = false })
    },
  },
  setup() {
    const invoiceData = ref({
      master: {
        id: 0,
        date: '',
        due_date: '',
        description: '',
        based_document_id: '',
        based_document_type_id: '',
        based_document_no: '',
        organization_branch_id: '',
        document_no: '',
        total_price: 0,
        warehouse_id: '',
        financial_year_id: 0,
        status_id: '',
        suspended: false,
      },
      clientOrVenderId: 1,
      document_id: 11,
      document: '',
      clientOrVenderName: 'Supplier',
      client: null,
      items: [],
      file: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      documentName: 'Invoice',
      routEdit: 'purchase-updatePurchasesInvoice',
      routePerview: 'purchase-previewInvoice',
      permissionEdit: 'PurchasesInvoice',
      to: 'Invoice To',
      tableItems: 'purchase_invoice_details',
      send: true,
      preview: false,
      reset: true,
      save: true,
      edit: false,
      isEdit: false,
      payment: false,
      print: false,
      download: false,
      saveUpdateText: 'Save',
    })
    const itemFormBlankItem = {
      item: null,
      item_id: 0,
      price: 0,
      quantity: 1,
      measurement_unit: null,
      measurement_unit_id: 0,
    }
    return {
      invoiceData,
      itemFormBlankItem,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
